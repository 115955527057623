import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonService } from 'src/app/Services/common.service';
declare var $ : any
@Component({
  selector: 'custom-opt-input',
  templateUrl: './custom-opt-input.component.html',
  styleUrls: ['./custom-opt-input.component.css']
})
export class CustomOptInputComponent implements OnInit {
  @Output() onInputChange = new EventEmitter
  @Input() length : number = 6
  digit : any = []
 
  inputType = 'text'
  public arr  = []
 
  constructor(public commonService: CommonService) { 
   this.arr = new Array(this.length)
    
  }

  ngOnInit(): void {
   setTimeout(() => {
    this.initialise_otp()
   }, 100);
  }
  initialise_otp(){
    $(".digit-group")
    .find("input")
    .each(function () {
      $(this).attr("maxlength", 1);
      $(this).on("keyup", function (e) {
        var parent = $($(this).parent());
  
        if (e.keyCode === 8 || e.keyCode === 37) {
          var prev = parent.find("input#" + $(this).data("previous"));
  
          if (prev.length) {
            $(prev).select();
          }
        } else if (
          (e.keyCode >= 48 && e.keyCode <= 57) ||
          (e.keyCode >= 65 && e.keyCode <= 90) ||
          (e.keyCode >= 96 && e.keyCode <= 105) ||
          e.keyCode === 39
        ) {
          var next = parent.find("input#" + $(this).data("next"));
  
          if (next.length) {
            $(next).select();
          } else {
            if (parent.data("autosubmit")) {
              parent.submit();
            }
          }
        }
      });
    });
  }

  getOtp(){
   let otp_output = this.digit.toString()
   otp_output = otp_output.split(',').join('')
   this.onInputChange.emit(otp_output)
  }

}
