<div class="splash-container">
    <div class="card login-section">
        <div class="login_box_outer">

            <div class="text-center p-3">
                <a href="../index.html">
                    <img class="logo-img" src="../assets/images/logo.png" alt="logo"></a>

            </div>
            <div class="card-body">
                <form [formGroup]="loginForm">
                    <div class="form-group">
                        <input class="form-control form-control-lg" id="username" type="text"
                            placeholder="Email Address" formControlName="email" autocomplete="off" maxlength="60">
                        <div class="text-danger"
                            *ngIf="loginForm.get('email').invalid && (loginForm.get('email').dirty || loginForm.get('email').touched)">
                            <span *ngIf="loginForm.get('email').hasError('required')">*Email address is
                                required.</span>

                            <span *ngIf="loginForm.get('email').hasError('pattern')">*Please enter valid email.</span>

                        </div>
                    </div>
                    <div class="form-group positon-relative">
                        <input [type]="newPassword ? 'password' : 'text'" class="form-control form-control-lg"
                            id="password" type="password" formControlName="password" placeholder="Password"
                            maxlength="32">
                        <i *ngIf="!newPassword" class="fa fa-eye eye-icon" (click)="newPassword = !newPassword"></i>
                        <i *ngIf="newPassword" class="fa fa-eye-slash eye-icon" (click)="newPassword = !newPassword"></i>

                        <div class="text-danger"
                            *ngIf="loginForm.get('password').invalid && (loginForm.get('password').dirty || loginForm.get('password').touched)">
                            <span *ngIf="loginForm.get('password').hasError('required')">*Password is
                                required.</span>
                            <span *ngIf="loginForm.get('password').hasError('pattern')">*Password should have
                                minimum 8
                                characters, at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special
                                character.</span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="custom-control custom-checkbox d-inline-flex custom-file-naked">
                            <input class="custom-control-input" type="checkbox" formControlName="rememberMe" /><span
                                class="custom-control-label font-normal">Remember Me</span>
                        </label>
                        <a routerLink='/forget-password'
                            class="footer-link float-right pt-1 font-bold forgot-password">Forgot Password</a>
                    </div>
                    <button type="submit" class="btn bg-green btn-lg btn-block font-bold btn-green br15"
                        [disabled]="loginForm.invalid" (click)="onLogin()">Login</button>
                </form>
            </div>
        </div>
    </div>
</div>