import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { CommonService } from 'src/app/Services/common.service';
import { ApiFunctionalityService } from 'src/app/Services/api-functionality.service';
import { filter } from 'rxjs/operators';
declare var $: any;

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css'],
  // host: {
  //   "(window:resize)":"onWindowResize($event)"
  // }
})
export class SideBarComponent implements OnInit {
  sidebarActive: any;
  activeNavigate: any
  sidemenu: any = []
  permission: any;
  userDetails: any;

  constructor(public router: Router, public commonService: CommonService, public apiService: ApiFunctionalityService) {
    this.initialsideMenu()
    router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((event: NavigationStart) => {
      this.activeNavigate = event.url
      this.sidemenu.filter((element) => {
        if (element.routerLink == event.url) {
          this.sidebarActive = element.routerLink
        }
      })
    })

  }


  ngOnInit(): void {
    $('.btn-toggle,.close_panel').click(() => {
      $('body').toggleClass('toggle-wrapper');
    });
    $('.sidebar').hover(() => {

      if ($('body').hasClass('toggle-wrapper')) {
        $('body').toggleClass('toggle-wrapper');

      }
      else {

      }

    });

    let sidemenu;
    this.commonService.loginData.subscribe((res: any) => {
      if (res) {
        this.userDetails = res
        this.permission = res.permission
        // check permission
        // this.checkPermission()
      }
    })

    if (this.commonService.isLoggedIn()) {
      this.getProfile()
    }
  }

  initialsideMenu(){
   
    this.sidemenu = [
      { "id": 1, "routerLink": "/dashboard", "icon": "fa fa-dashboard", permissionKey: 'Dashboard', "Name": "Dashboard" },
      { "id": 2, "routerLink": "/userManagement", "icon": "fa fa-users", permissionKey: 'userManagement', "Name": "User Management" },
      {
        "id": 3, "routerLink": "/admin", "icon": "fa fa-user", permissionKey: 'subAdminManagement', "Name": "Sub Admin", "subNameList": [
          { "id": 1, "routerLink": "", permissionKey: 'List', "subName": "Sub Admin List" },
          { "id": 2, "routerLink": "/add-admin", permissionKey: 'Add', "subName": "Add Sub Admin" }
        ]
      },
      {
        "id": 4, "routerLink": "/walletManagement", "icon": "fa fa-usd", permissionKey: 'walletManagement', "Name": "Wallet Management"
      },
      {
        "id": 5, "routerLink": "/feeManagement", "icon": "fa fa-exchange", permissionKey: 'feeManamegemt', "Name": "Fee Management", 
      },
      {
        "id": 6, "routerLink": "/poolHistory", "icon": "fa fa-hdd-o", permissionKey: 'poolHistoryManagement', "Name": "Liquidity Management"
      },
      {
        "id": 7, "routerLink": "/coupon", "icon": "fa fa-gift", permissionKey: 'couponManagement', "Name": "Coupon Management"
      },
      {
        "id": 8, "routerLink": "/hotWalletManagement", "icon": "fa fa-money", permissionKey: 'hotWalletManagement', "Name": "Hot Wallet Management"
      },
      {
        "id": 9, "routerLink": "/kycManagement", "icon": "fa fa-book", permissionKey: 'kycManagment', "Name": "KYC Management"
      },
      { "id": 10, "routerLink": "/static-content", "icon": "fa fa-file", permissionKey: 'staticContent', "Name": "Static Content" },
      { "id": 11, "routerLink": "/faq", "icon": "fa fa-question-circle", permissionKey: "faqManagemet", "Name": "FAQ's" }
     
    ]
  }
  onSelect(sidebar) {
    this.sidebarActive = sidebar
  }

  nagivateToComponent(link, activeName?) {
    this.sidebarActive = activeName
    this.router.navigate([link])
  }

  // get admin profile
  getProfile() {
    let url = `admin/getProfile`
    this.apiService.getApi(url, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.commonService.loginData.next(res.result)
        this.userDetails = res.result
        
        if(res.result.userType == 'SUBADMIN'){
          this.checkPermission(res.result.permission)

        }
      
      }
    })
  }
  count: any = 0


  checkPermission(permission) {

    localStorage.setItem('permission', JSON.stringify(this.permission))
    localStorage.setItem('userType', this.userDetails.userType)
    console.log("permission->", this.permission)
    let sidemenu = []
    sidemenu.push(this.sidemenu[0])
    if (this.userDetails.userType == 'SUBADMIN') {
      var permissionArray = []
     

      for (const item of permission) {
        if (item.isPermission) {
          permissionArray.push(item.managementName)

          let findPerm = this.sidemenu.find((ele)=>{
            return ele.permissionKey == item.managementName
          })
          sidemenu.push(findPerm)
        }
      }
    
      

    
      // console.log(permissionArray)
     
      this.sidemenu = sidemenu
      console.log(this.sidemenu)
    }
  }

}
