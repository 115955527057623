import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
// import { AuthGuard } from './auth.guard';
import { ChangePasswordComponent } from './pages/Auth/change-password/change-password.component';
import { ForgetPasswordComponent } from './pages/Auth/forget-password/forget-password.component';
import { LoginComponent } from './pages/Auth/login/login.component';
import { OtpVerificationComponent } from './pages/Auth/otp-verification/otp-verification.component';
import { ResetPasswordComponent } from './pages/Auth/reset-password/reset-password.component';
import { PreloadingStrategyService } from './Services/preloading-strategy.service';
import { AuthGuard } from './guard/auth.guard';
import { DashboardGuard } from './guard/dashboard.guard';
// import { HomeGuard } from './guard/home.guard';

const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  { path: 'login', component: LoginComponent, canActivate: [AuthGuard] },
  { path: 'forget-password', component: ForgetPasswordComponent, canActivate: [AuthGuard] },
  { path: 'reset-password', component: ResetPasswordComponent, canActivate: [AuthGuard] },
  { path: 'otp-verification', component: OtpVerificationComponent, canActivate: [AuthGuard] },
  { path: 'change-password', component: ChangePasswordComponent, canActivate: [DashboardGuard] },

  { path: 'dashboard',data: { preload: true }, loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [DashboardGuard] },
  { path: 'userManagement',data: { preload: true }, loadChildren: () => import('./pages/user-management/user-management.module').then(m => m.UserManagementModule), canActivate: [DashboardGuard] },
  { path: 'kycManagement',data: { preload: true }, loadChildren: () => import('./pages/kyc-management/kyc-management.module').then(m => m.KycManagementModule), canActivate: [DashboardGuard] },
  { path: 'walletManagement',data: { preload: true }, loadChildren: () => import('./pages/wallet-management/wallet-management.module').then(m => m.WalletManagementModule), canActivate: [DashboardGuard] },
  { path: 'feeManagement',data: { preload: true }, loadChildren: () => import('./pages/fee-management/fee-management.module').then(m => m.FeeManagementModule), canActivate: [DashboardGuard] },
  { path: 'hotWalletManagement',data: { preload: true }, loadChildren: () => import('./pages/hot-wallet/hot-wallet.module').then(m => m.HotWalletModule), canActivate: [DashboardGuard] },
  { path: 'coupon',data: { preload: true }, loadChildren: () => import('./pages/coupon-manangement/coupon-manangement.module').then(m => m.CouponManangementModule), canActivate: [DashboardGuard] },
  { path: 'poolHistory',data: { preload: true }, loadChildren: () => import('./pages/pool-history-management/pool-history-management.module').then(m => m.PoolHistoryManagementModule), canActivate: [DashboardGuard] },
  
  
  
  
  
  { path: 'admin',data: { preload: true }, loadChildren: () => import('./pages/administration-managemnt/administration-managemnt.module').then(m => m.AdministrationManagemntModule), canActivate: [DashboardGuard],canActivateChild: [DashboardGuard], },
  { path: 'static-content', loadChildren: () => import('./pages/static-content-management/static-content-management.module').then(m => m.StaticContentManagementModule), canActivate: [DashboardGuard] },
  { path: 'faq', loadChildren: () => import('./pages/faq-management/faq-management.module').then(m => m.FaqManagementModule), canActivate: [DashboardGuard] },
  { path: 'setting', data: { preload: true }, loadChildren: () => import('./pages/setting-management/setting-management.module').then(m => m.SettingManagementModule), canActivate: [DashboardGuard] },
  

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule {
  /**
   * @Note :- route notes add 'data: { preload: true },'
   * For custom preloading Module
   * In --> Routes
   * 
   */

}
