import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { BnNgIdleService } from 'bn-ng-idle';
import { filter } from 'rxjs/operators';
import { AuthServiceService } from './service-provider/auth-service.service';
import { ApiFunctionalityService } from './Services/api-functionality.service';
import { CommonService } from './Services/common.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'any';
  currentUrl: any
  show: boolean = true
  // notFound:boolean=false
  constructor(private router: Router, public service: AuthServiceService, private bnIdle: BnNgIdleService, public commonService: CommonService,public apiService :ApiFunctionalityService) {

    router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((event: NavigationStart) => {
      if (event.url.includes('change-password')) {
        return !this.show
      }
    })

    

   
  }
  idletime = 0
    ngOnInit(): void {
    

    }
    


   

   
   

}
